import React, { useEffect } from 'react';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Redirect = () => {
    const { id } = useParams()
    // const query = useQuery();
    // console.log('QUERY', id)
    // const id = query.get('id');

    const getDetails = async () => {
        try {
            let getData = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/public/${id}`)
            if (getData?.status != 200) {
                // setAsset({})
            } else {
                // setAsset(getData.data.url)
                // window.location.href = getData.data[0].url;
                // Check if the URL starts with http:// or https://
                let url = getData.data[0].url
                if (!/^https?:\/\//i.test(url)) {
                    // If not, add the default protocol (https://)
                    window.location.href = `https://${url}`;
                } else {
                    window.location.replace(url)
                }

            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (id) {
            getDetails()
        }
    }, [id])

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center',
        }}>
            <img src={"zv_loader.png"} alt="ZongoVita Tech Pvt Ltd Logo" />
            <div className="" style={{ display: "flex" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your gateway
                to <br></br>a smart and intuitive space
            </div>
        </div>
    )
}

export default Redirect